import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const Disclaimer = () => (
  <Row>
    <Col className="bg-dark">
      <Row>
        <Col xs={12} lg={{ offset: 4, span: 8 }} xl={{ offset: 4, span: 7 }} className="py-5">
          <div className="text-center">
            <p>
              Investorer gøres opmærksomme på, at investering kan være forbundet med risiko for tab,
              som ikke på forhånd kan fastlægges, og at historiske afkast er ikke en garanti for
              fremtidige afkast.
            </p>
            <p>
              Grafen og oplysningerne om forventet afkast kan ikke anses som en pålidelig indikator
              for den fremtidige kursudvikling, ligesom det historiske afkast og den viste
              kursudvikling ikke kan anvendes som en pålidelig indikator for fremtidige afkast.
              Afkast på denne side er beregnet før skat, som kan afhænge af den enkelte investors
              individuelle situation. Både det forventede afkast og det historiske afkast er vist
              uden omkostninger, men der er altid omkostninger forbundet med investering, og disse
              omkostninger vil have en negativ påvirkning på det faktiske.
            </p>
            <p>
              På baggrund af de informationer, som du har oplyst om dine forhold, herunder
              risikovillighed og tidshorisont, har vi udarbejdet en investeringsplan, som den ville
              kunne se ud. Hvis du ønsker at modtage investeringsrådgivning fra NORD, forudsætter
              det, at du bliver kunde hos NORD, og at du indgår en aftale om investeringsrådgivning
              med NORD. Den fremsendte investeringsplan skal ikke anses som investeringsrådgivning,
              og hvis du disponerer uden at have indgået en investeringsaftale med NORD, sker det på
              dit eget ansvar.
            </p>
            <p>
              For NORD's ansvarlige porteføljer antages samme forventede afkast og risici, som for
              NORD's standard porteføljer. De indeks som de ansvarlige fonde replicerer, ligger tæt
              op af de indeks NORD's standard porteføljer replicerer hvad angår de underliggende
              værdipapirer i de pågældende indeks og der findes ikke en lang nok historik til at
              foretage beregninger for forventede afkast samt risici, når der tages afsæt i de
              ansvarlige porteføljers indeks. Hvis man dog alligevel kigger på den korte historik
              der findes for de ansvarlige fonde, så ligger deres afkast og risiko tilnærmelsesvist
              tæt op af NORD standard porteføljernes fonde.
            </p>
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
)

export default Disclaimer
