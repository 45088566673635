import BaffleButton from '@nord/ui/src/components/BaffleButton'
import useApi from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useWaitForStateChange from '@nord/ui/src/hooks/useWaitForStateChange'
import React, { useState, useEffect, useCallback } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'

import {
  selectCurrentPortfolioId,
  selectOnboardingState,
} from '../../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../../store/current/user'
import extractOnboardingState from '../../../../utilities/extractOnboardingState'
import ContentContainer from '../../ContentContainer'
import ContentTitle from '../../ContentTitle'
import TimeoutProgressBar from '../../TimeoutProgressBar'

const StartPage = () => {
  const [timedOut, setTimedOut] = useState(false)
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const currentOnboardingState = useSelector(selectOnboardingState)
  // @ts-expect-error TS(2339) FIXME: Property 'saxoVerificationUrl' does not exist on t... Remove this comment to see the full error message
  const { saxoVerificationUrl } = useObjectSelector(selectCurrentUser)
  const initiateSaxoVerification = useApi('/onboarding/:portfolioId/saxo_verification', {
    method: 'POST',
    withCredentials: true,
  })

  const handleTimeout = useCallback(() => setTimedOut(true), [])

  useWaitForStateChange(['saxo_verification_failed', 'ready_for_verification'], handleTimeout, {
    currentState: currentOnboardingState,
    extractNewState: extractOnboardingState,
  })

  useEffect(() => {
    if (!portfolioId) return

    if (currentOnboardingState === 'documents_signed')
      initiateSaxoVerification(undefined, { pathParams: { portfolioId } })
  }, [currentOnboardingState, initiateSaxoVerification, portfolioId])

  const renderAction = () => {
    if (timedOut) {
      return (
        <div>
          <Alert variant="warning">
            Vi kan ikke starte din verificering hos Saxo Bank. Prøv at genindlæs siden. Hvis dette
            ikke virker, så kontakt os venligst, så kan vi hjælpe dig videre.
          </Alert>
        </div>
      )
    }

    if (currentOnboardingState !== 'ready_for_verification') {
      return (
        <div>
          <div className="mb-2">Vi forbereder dit verificerings link, vent et øjeblik...</div>
          <TimeoutProgressBar />
        </div>
      )
    }

    return (
      <BaffleButton variant="primary" type={undefined} as="a" href={saxoVerificationUrl}>
        Fortsæt til verificering
      </BaffleButton>
    )
  }

  return (
    <ContentContainer>
      <ContentTitle title="Du er der næsten..." />
      <p className="text-center">
        Vi mangler kun at verificere dig! Når du trykker nedenfor, kommer du til en ny side hvor du
        kan logge ind med NemID eller MitID, for at verificere dig hos Saxo Bank og færdiggøre din
        oprettelse.
      </p>
      <Row>
        <Col className="text-center mt-4">{renderAction()}</Col>
      </Row>
    </ContentContainer>
  )
}

export default StartPage
