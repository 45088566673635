import AlertModal from '@nord/ui/src/components/AlertModal'
import BaffleButton from '@nord/ui/src/components/BaffleButton'
import IconButton from '@nord/ui/src/components/IconButton'
import LoadingButton from '@nord/ui/src/components/LoadingButton'
import useApi from '@nord/ui/src/hooks/useApi'
import useInitial from '@nord/ui/src/hooks/useInitial'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useQuery from '@nord/ui/src/hooks/useQuery'
import callIntercom from '@nord/ui/src/utilities/callIntercom'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import queryString from 'query-string'
import React, { useState, useEffect, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link, useHistory } from 'react-router-dom'

import { SHARED_PATHS, PENSION_PATHS } from '../../../../configuration/constants'
import useFlow from '../../../../hooks/useFlow'
import useSignedIn from '../../../../hooks/useSignedIn'
import {
  createCurrentPortfolioOnboarding,
  updateCurrentPortfolioOnboarding,
  changeOnboardingState,
  selectCurrentPortfolio,
  selectOnboardingState,
  selectCurrentPortfolioId,
} from '../../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../../store/current/user'
import pushToDataLayer, { pushEventToDataLayer } from '../../../../utilities/pushToDataLayer'
import AllocationSection from '../../Shared/InvestmentPlan/ResultPage/AllocationSection'
import Costs from '../../Shared/InvestmentPlan/ResultPage/Costs'
import HistoricalReturnSection from '../../Shared/InvestmentPlan/ResultPage/HistoricalReturnSection'
import Portfolio from '../../Shared/InvestmentPlan/ResultPage/Portfolio'
import ResponsibleInvestment from '../../Shared/InvestmentPlan/ResultPage/ResponsibleInvestment'
import ResultPageContext from '../../Shared/InvestmentPlan/ResultPage/ResultPageContext'
import SignUpModal from '../../Shared/SignUpModal'

import Escalation from './Escalation'
import PensionInfo from './PensionInfo'

const trackIntercomEvents = () => {
  callIntercom('trackEvent', 'email-investmentplan')

  const utm = Cookies.getJSON('utm')

  if (utm !== undefined && utm.source === 'mybanker') {
    callIntercom('trackEvent', 'my-banker')
  }
}

const ResultPage = () => {
  const dispatch = useDispatch()
  const signedIn = useSignedIn()
  const flow = useFlow()
  const history = useHistory()
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'investmentProfileAttributes' does not ex... Remove this comment to see the full error message
    investmentProfileAttributes,
    // @ts-expect-error TS(2339) FIXME: Property 'advisedRiskScore' does not exist on type... Remove this comment to see the full error message
    advisedRiskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'depotType' does not exist on type 'unkno... Remove this comment to see the full error message
    depotType,
    // @ts-expect-error TS(2339) FIXME: Property 'migrationDepot' does not exist on type '... Remove this comment to see the full error message
    migrationDepot,
    // @ts-expect-error TS(2339) FIXME: Property 'expectedInvestment' does not exist on ty... Remove this comment to see the full error message
    expectedInvestment,
    // @ts-expect-error TS(2339) FIXME: Property 'escalation' does not exist on type 'unkn... Remove this comment to see the full error message
    escalation: initialEscalation,
    // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
    riskScore: initialRiskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'onboardingState' does not exist on type ... Remove this comment to see the full error message
    onboardingState: portfolioOnboardingState,
    // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'unknown'.
    title: portfolioTitle,
    // @ts-expect-error TS(2339) FIXME: Property 'pensionsInfoMigration' does not exist on... Remove this comment to see the full error message
    pensionsInfoMigration,
  } = useObjectSelector(selectCurrentPortfolio)
  // @ts-expect-error TS(2339) FIXME: Property 'birthdate' does not exist on type 'unkno... Remove this comment to see the full error message
  const { birthdate } = useObjectSelector(selectCurrentUser)

  const queryParams = useQuery()
  const {
    riskScore: initialRiskScoreParam,
    email: emailParam,
    type: initialTypeParam,
    depotType: depotTypeParam,
  } = useInitial(queryParams)

  const [loading, setLoading] = useState(true)
  const [showWarning, setShowWarning] = useState(false)
  const [riskScoreData, setRiskScoreData] = useState({})
  let riskScoreParam = initialRiskScoreParam
  riskScoreParam = riskScoreParam ? parseInt(riskScoreParam, 10) : null
  if (riskScoreParam > 6 || riskScoreParam < 1) riskScoreParam = null
  const [riskScore, setRiskScore] = useState(
    riskScoreParam || initialRiskScore || advisedRiskScore || 6,
  )
  const [escalation, setEscalation] = useState(initialEscalation || true)
  const onboardingState = useSelector(selectOnboardingState)
  const getPensionRiskScore = useApi('/risk_profile/pension_risk_data')

  // @ts-expect-error TS(2339) FIXME: Property 'yearsToPension' does not exist on type '... Remove this comment to see the full error message
  const { yearsToPension, currentAge: age } = riskScoreData
  const currentRiskScore = riskScore || advisedRiskScore || 6

  const typeParam = initialTypeParam || depotTypeParam
  let currentDepotType = typeParam || depotType
  if (currentDepotType === undefined || currentDepotType === 'frie_midler')
    currentDepotType = 'ratepension'

  const createdInSaxo = portfolioOnboardingState === 'created_in_saxo'
  const submittedToSaxo = portfolioOnboardingState === 'submitted_to_saxo' || createdInSaxo
  const personalized = !!advisedRiskScore
  const emailParamPresent = emailParam && emailParam.length > 0
  const userAlreadyOnboarded = signedIn && onboardingState === 'saxo_onboarded'

  useEffect(() => {
    if (initialRiskScore || advisedRiskScore) setRiskScore(initialRiskScore || advisedRiskScore)
  }, [initialRiskScore, advisedRiskScore])

  useEffect(() => {
    if (initialEscalation !== null && initialEscalation !== undefined)
      setEscalation(initialEscalation)
  }, [initialEscalation])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getPensionRiskScore({
        depotType: currentDepotType,
        birthdate,
      })
      setRiskScoreData(data)
      setLoading(false)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthdate, depotType])

  // @ts-expect-error TS(7031) FIXME: Binding element 'newPensionRiskScore' implicitly h... Remove this comment to see the full error message
  const handleChangeRiskScore = ({ riskScore: newPensionRiskScore, escalation: newEscalation }) => {
    setRiskScore(newPensionRiskScore)
    setEscalation(newEscalation)
  }

  const handleSaveRiskScore = async () => {
    const updatedValues = await dispatch(
      updateCurrentPortfolioOnboarding({
        riskScore: currentRiskScore,
        escalation,
      }),
    )

    trackIntercomEvents()

    pushEventToDataLayer({
      riskScore,
      event: 'risk_profile_chosen',
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '(dispatch: a... Remove this comment to see the full error message
      userID: updatedValues.id,
      expectedInvestment,
      flow,
    })
  }

  const handleStartOnboarding = () => {
    if (escalation === false && showWarning === false) {
      setShowWarning(true)

      return
    }

    if (showWarning) setShowWarning(false)

    handleSaveRiskScore()
    history.push(PENSION_PATHS.onboarding(portfolioId))
  }

  const [showSelect, setShowSelect] = useState(false)

  const handleRejectWarning = () => {
    setRiskScore(advisedRiskScore)
    setEscalation(true)
    setShowWarning(false)
    setShowSelect(false)
  }

  const handleRestartInvestmentPlan = () => {
    dispatch(changeOnboardingState('user_created'))
  }

  const [showSignupModal, setShowSignupModal] = useState(true)

  let callToActionButton
  if (loading) {
    callToActionButton = null
  } else if (!showSignupModal) {
    callToActionButton = (
      <IconButton
        variant="success"
        icon={ArrowRight}
        onClick={() => setShowSignupModal(true)}
        filled
      >
        Opret dig og gem
      </IconButton>
    )
  } else if (signedIn && personalized && !userAlreadyOnboarded && portfolioId) {
    callToActionButton = (
      // @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; onClick... Remove this comment to see the full error message
      <LoadingButton variant="success" onClick={handleStartOnboarding} data-cy="next-button" filled>
        Næste
      </LoadingButton>
    )
  } else if (emailParamPresent) {
    callToActionButton = (
      <Button
        variant="success"
        type={undefined}
        as={Link}
        to={{
          pathname: SHARED_PATHS.userSignIn(),
          search: `?${queryString.stringify({
            email: emailParam,
            // eslint-disable-next-line camelcase
            redirect_to: PENSION_PATHS.onboarding(portfolioId),
          })}`,
        }}
        filled
      >
        Næste
      </Button>
    )
  } else if (portfolioId && !userAlreadyOnboarded) {
    callToActionButton = (
      <BaffleButton
        variant="success"
        as={Link}
        type={undefined}
        to={PENSION_PATHS.investmentPlan(portfolioId)}
        onClick={handleRestartInvestmentPlan}
        filled
      >
        Få din personlige investeringsplan
      </BaffleButton>
    )
  }

  const handleSignUp = async (data: Record<string, unknown>) => {
    const { id: userId, firstName, lastName, email, phone, phonePrefix } = data
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '(dispatch: a... Remove this comment to see the full error message
    const { id } = await dispatch(
      createCurrentPortfolioOnboarding({
        portfolio: {
          advisedRiskScore,
          depotType,
          expectedInvestment,
          migrationDepot,
          escalation,
          pensionDepot: true,
          pensionsInfoMigration,
          investmentProfileAttributes,
        },
        user: {
          birthdate,
        },
      }),
    )

    pushEventToDataLayer({
      event: 'investment_plan_completed',
      expectedInvestment,
      flow,
      portfolioId: id,
      userId,
      firstName,
      lastName,
      email,
      phoneNumber: `+${phonePrefix}${phone}`,
    })
  }

  // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
  if (window.dataLayer)
    pushToDataLayer({
      experimentId: process.env.REACT_APP_GOOGLE_OPTIMIZE_EXPERIMENT_ID,
      variationId: process.env.REACT_APP_GOOGLE_OPTIMIZE_VARIATION_ID,
    })

  const containerStyle = {}

  if (advisedRiskScore && !signedIn && showSignupModal) {
    // @ts-expect-error TS(2339) FIXME: Property 'filter' does not exist on type '{}'.
    containerStyle.filter = 'blur(3px)'
  }

  let title
  if (submittedToSaxo) {
    title = `Investeringsplan for din portefølje: ${portfolioTitle}`
  } else if (personalized) {
    title = `Din nye ${currentDepotType}`
  } else {
    title = `Investeringsplan for ${currentDepotType}`
  }

  const isLongTitle = title.length >= 30

  const pageContext = useMemo(
    () => ({
      loading,
      riskScore: currentRiskScore,
      stocksOnly: false,
      esg: true,
      pension: true,
      simple: false,
      expectedInvestment,
    }),
    [expectedInvestment, loading, currentRiskScore],
  )

  return (
    <ResultPageContext.Provider value={pageContext}>
      <div style={containerStyle} className="pt-5">
        {advisedRiskScore && (
          <SignUpModal
            show={!signedIn && showSignupModal}
            onSignUp={handleSignUp}
            onClose={() => setShowSignupModal(false)}
          />
        )}
        <Row>
          <Col xs={12} xl={{ offset: 1, span: 10 }}>
            <h1 className={classNames('text-center', { h2: isLongTitle })}>{title}</h1>
          </Col>
        </Row>
        {personalized && (
          <Row>
            <Col xs={12} xl={{ offset: 2, span: 8 }}>
              <p className="text-center">
                Baseret på det vi ved om dig samt det du har fortalt os, anbefaler vi dig
                risikoprofil P{advisedRiskScore}. Det mener vi, er den rigtige risikoprofil til dig.
                Du kan altid ændre din risikoprofil senere.
              </p>
            </Col>
          </Row>
        )}
        <div className="clearfix mb-lg-5 mb-md-4 mb-sm-3" />
        <Row>
          <Col xs={12} lg={4} xl={{ offset: 1, span: 3 }}>
            <PensionInfo
              riskScore={riskScore}
              depotType={currentDepotType}
              advisedRiskScore={advisedRiskScore}
              yearsToPension={yearsToPension}
              age={age}
              onChange={handleChangeRiskScore}
              loading={loading}
              onboardingButton={callToActionButton}
              escalation={escalation}
              personalized={personalized}
              submittedToSaxo={submittedToSaxo}
              showSelect={showSelect}
              onChangeShowSelect={setShowSelect}
            />
          </Col>
          <Col xs={12} lg={8} xl={7}>
            <Escalation
              advisedRiskScore={advisedRiskScore}
              yearsToPension={yearsToPension}
              depotType={currentDepotType}
              riskScore={currentRiskScore}
              chosen={escalation}
              personalized={personalized}
            />
            <Costs />
            <ResponsibleInvestment />
            <AllocationSection />
            <Portfolio />
            <HistoricalReturnSection />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ offset: 2, span: 8 }}>
            <div className="text-center">{callToActionButton}</div>
          </Col>
        </Row>

        <Row>
          <Col className="bg-dark">
            <Row>
              <Col xs={12} lg={{ offset: 2, span: 8 }} className="py-5">
                <div className="text-center">
                  <p>
                    Investorer gøres opmærksomme på, at investering kan være forbundet med risiko
                    for tab, som ikke på forhånd kan fastlægges, og at historiske afkast er ikke en
                    garanti for fremtidige afkast.
                  </p>
                  <p>
                    Grafen og oplysningerne om forventet afkast kan ikke anses som en pålidelig
                    indikator for den fremtidige kursudvikling, ligesom det historiske afkast og den
                    viste kursudvikling ikke kan anvendes som en pålidelig indikator for fremtidige
                    afkast. Afkast på denne side er beregnet før skat, som kan afhænge af den
                    enkelte investors individuelle situation. Både det forventede afkast og det
                    historiske afkast er vist uden omkostninger, men der er altid omkostninger
                    forbundet med investering, og disse omkostninger vil have en negativ påvirkning
                    på det faktiske.
                  </p>
                  <p>
                    På baggrund af din forventede folkepensionsalder har vi udarbejdet en
                    pensionssplan som den ville kunne se ud. Hvis du ønsker at modtage
                    investeringsrådgivning fra NORD, forudsætter det, at du bliver kunde hos NORD,
                    og at du indgår en aftale om investeringsrådgivning med NORD. Den fremsendte
                    pensionssplan skal ikke anses som investeringsrådgivning, og hvis du disponerer
                    uden at have indgået en investeringsaftale med NORD, sker det på dit eget
                    ansvar.
                  </p>
                  <p>
                    For NORD's ansvarlige porteføljer antages samme forventede afkast og risici, som
                    for NORD's standard porteføljer. De indeks som de ansvarlige fonde replicerer,
                    ligger tæt op af de indeks NORD's standard porteføljer replicerer hvad angår de
                    underliggende værdipapirer i de pågældende indeks og der findes ikke en lang nok
                    historik til at foretage beregninger for forventede afkast samt risici, når der
                    tages afsæt i de ansvarlige porteføljers indeks. Hvis man dog alligevel kigger
                    på den korte historik der findes for de ansvarlige fonde, så ligger deres afkast
                    og risiko tilnærmelsesvist tæt op af NORD standard porteføljernes fonde.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <AlertModal
          show={showWarning}
          title="Afvigelse fra anbefaling"
          onHide={handleRejectWarning}
          onAccept={handleStartOnboarding}
          acceptable
        >
          <p>
            Er du sikker på, at du vil ændre risikoprofil fra P{advisedRiskScore} (med nedtrapning)
            til P{riskScore} (uden nedtrapning)? Dermed går du i mod vores anbefaling og din
            portefølje vil ikke blive nedtrappet gradvist.
          </p>
        </AlertModal>
      </div>
    </ResultPageContext.Provider>
  )
}

export default ResultPage
